import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

import { config } from '../config';

export const useRouter = () => {
  const { pathname } = useLocation();
  const path = /\/$/.test(pathname) ? pathname.slice(0, -1) : pathname;

  const navigateWithSearchParams = (
    to: string,
    options?: { state?: object; replace?: boolean },
  ) => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('q')) {
      queryParams.delete('q');
    }
    const search = queryParams.toString().length
      ? `?${queryParams.toString()}`
      : '';
    navigate(to + search, options);
  };

  const navigateToQuizStart = () => {
    const queryParams = new URLSearchParams(location.search);
    const question = queryParams.get('q');
    const nextQuestion = question ? (Number(question) + 1).toString() : '2';
    queryParams.set('q', nextQuestion);

    navigate('/start?' + queryParams.toString());
  };

  const navigateToQuizQuestion = () => {
    const queryParams = new URLSearchParams(location.search);
    const question = queryParams.get('q');
    const nextQuestion = question ? (Number(question) + 1).toString() : '2';
    queryParams.set('q', nextQuestion);
    // navigate('?' + queryParams.toString());
    const newUrl = '?' + queryParams.toString();
    window.history.pushState({}, '', newUrl);
    window.dispatchEvent(new Event('virtualPageView'));
  };

  return {
    goToFAQ: () => navigateWithSearchParams('/faq'),
    goToReviews: () => navigateWithSearchParams('/reviews'),
    goToStarterGuide: () => navigateWithSearchParams('/single'),
    goToStarterGuideMobile: () => navigateWithSearchParams('/single-mobile'),
    goToTerms: () => navigateWithSearchParams('/terms'),
    goToTermsMobile: () => navigateWithSearchParams('/terms-mobile'),
    goToPrivacy: () => navigateWithSearchParams('/privacy'),
    goToPrivacyMobile: () => navigateWithSearchParams('/privacy-mobile'),
    goToContact: () => navigateWithSearchParams('/contact'),
    goToStory: () => navigateWithSearchParams('/story'),
    goToCases: () => navigateWithSearchParams('/cases'),
    goToQuizStart: navigateToQuizStart,
    goToBookOnboarding: () =>
      navigateWithSearchParams('/start/onboarding-book'),
    goToOnBoarding: () => navigateWithSearchParams('/start/onboarding'),
    goToOnBoardingEmail: () =>
      navigateWithSearchParams('/start/onboardingEmail'),
    goToOnBoardingCheckout: () =>
      navigateWithSearchParams('/start/onboarding-checkout'),
    goToOnBoardingCalc: () =>
      navigateWithSearchParams('/start/onboarding-calculating'),
    goToLanding: () => navigateWithSearchParams('/'),
    goToQuizQuestion: navigateToQuizQuestion,
    goToQuiz: () => navigateWithSearchParams('/start'),
    goToEmail: () => navigateWithSearchParams('/start/email'),
    goToDiscount: () => navigateWithSearchParams('/start/discount'),
    goToRegister: () => navigateWithSearchParams('/start/register'),
    goToSummary: () => navigate('/start/summary'),
    goToCheckout: ({ discount }: { discount?: boolean }) =>
      navigateWithSearchParams(
        discount ? '/start/checkout-special' : '/start/checkout',
        {
          state: { discount: !!discount },
        },
      ),
    goToPayments: ({ discount }: { discount?: boolean }) =>
      navigateWithSearchParams('/start/checkout/payments', {
        state: { discount: !!discount },
      }),
    goToOnboardingPayments: ({ discount }: { discount?: boolean }) =>
      navigateWithSearchParams('/start/onboarding-checkout/payments', {
        state: { discount: !!discount },
      }),
    goToSuccess: () => navigateWithSearchParams('/start/success'),
    goToUpgrade: (item: string) => navigateWithSearchParams(`/start/${item}`),
    goToResults: () => navigateWithSearchParams('/start/results'),
    goToCalculating: () => navigateWithSearchParams('/start/calculating'),
    goToCreatePassword: (token: string) =>
      navigate(`${config.PATIENT_APP_BASE_URL}/create-password/${token}`),
    goToFreeConsultation: (token: string) =>
      navigate(`${config.PATIENT_APP_BASE_URL}/free-consultation/${token}`),
    closeModal: () => navigate(path.replace(/\/payments/, '')),
    navigateBack: () => navigate(-1),
    navigateWithSearchParams,
  };
};
